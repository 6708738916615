import React, {
  ChangeEvent, useEffect, useMemo,
} from 'react';
import {
  Box, Button, Divider, Grid, IconButton, MenuItem, MenuList, TableCell, TextField, Typography,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { SwapVertOutlined } from '@mui/icons-material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { capitalize } from 'lodash';
import moment from 'moment/moment';
import { GET_AWO_USERS, GET_PET_PARENTS, GET_PET_PARENTS_WITH_COORDINATORS } from '../../queries';
import DateFilter from '../DateFilter';
import ProfilesTable from '../ProfilesTable';
import ProfilesDownloadDataChip from '../ProfilesDownloadDataChip';
import withProfile from '../withProfile';
import { hashids } from '../../utilities';
import { PetParentTableView } from '../../types/PetParent';
import { useAwoContext } from '../AwoContextProvider';
import CoordinatorFiltersDialog from '../CoordinatorFiltersDialog';
import IconFilter from '../IconFilter';

interface ProfilesContainerProps {
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUserId: React.Dispatch<React.SetStateAction<number | null>>;
}

function ProfilesContainer({ setDrawerOpen, setUserId }: ProfilesContainerProps) {
  const { awo } = useAwoContext();
  const isCoSheltering = awo?.coSheltering;
  const isCoShelteringCoordinators = awo?.coShelteringCoordinators;
  const awoId = parseInt(awo?.id || '', 10);

  const [coordinatorFilterDialogOpen, setCoordinatorFilterDialogOpen] = React.useState(false);
  const [coordinatorFilters, setCoordinatorFilters] = React.useState<Set<string>>(new Set(JSON.parse(localStorage.getItem('coordinatorFilters') || '[]')));
  const { data: coordinatorsData } = useQuery(GET_AWO_USERS, {
    variables: {
      awoId,
      order: 'asc',
      orderBy: 'first_name',
    },
    skip: !isCoShelteringCoordinators,
  });
  const coordinators = coordinatorsData?.awoUsers || [];

  // even on chang the rowsPerPage/page, the value is still the same on GraphQL call
  // it might be a bug on MUI, so we need to use a ref to track the real value
  // useRefs to track "real" value, the state just for display in the UI
  const [searchTextTemp, setSearchTextTemp] = React.useState('');
  const [filters, setFilters] = React.useState({
    limit: 25,
    page: 0,
    order: 'desc',
    orderBy: 'mailchimp_sync_date',
    onlyGroupByPetParent: true,
    searchText: '',
    searchField: 'email',
    onboardingStatus: null,
    courseStatus: null,
    animalType: null,
    careType: null,
    smsStatus: null,
    interests: null,
    daysAgo: -1,
    coordinatorIds: Array.from(coordinatorFilters),
  });

  const location = useLocation();
  if (location.hash) {
    const id = location.hash.split('=')[1];
    const decodedId = hashids.decode(id);
    setDrawerOpen(true);
    setUserId(Number(decodedId[0]));
  }

  const profilesQuery = isCoSheltering ? GET_PET_PARENTS_WITH_COORDINATORS : GET_PET_PARENTS;

  const {
    loading, error, data, networkStatus, refetch,
  } = useQuery<{ petParents: PetParentTableView[], petParentCount: { count: number } }>(profilesQuery, {
    variables: {
      offset: 0,
      limit: 25,
      order: 'desc',
      orderBy: 'mailchimp_sync_date',
      onlyGroupByPetParent: true,
    },
    notifyOnNetworkStatusChange: true,
  });

  const headCells = useMemo(() => {
    const coShelteringProfile = [{
      id: 'first_name',
      width: '10%',
      label: 'Name',
      render(row: PetParentTableView) {
        return (
          <TableCell sx={{
            maxWidth: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          >
            {capitalize(row.firstName)}
          </TableCell>
        );
      },
    }, {
      id: 'dateCreated',
      width: '10%',
      label: 'Support Start',
      render(row: PetParentTableView) {
        return (
          <TableCell>{row.dateCreated ? moment(row.dateCreated).format('MM/DD/YYYY') : 'N/A'}</TableCell>
        );
      },
    }, {
      id: 'exitDate',
      width: '10%',
      label: 'Support End',
      render(row: PetParentTableView) {
        return (
          <TableCell>{row.exitDate ? moment(row.exitDate).add(30, 'days').format('MM/DD/YYYY') : 'In Progress'}</TableCell>
        );
      },
    }];
    if (isCoShelteringCoordinators) {
      coShelteringProfile.splice(1, 0, {
        id: 'coordinator',
        width: '10%',
        label: 'Coordinator',
        render(row: PetParentTableView) {
          return (
            <TableCell>{row.coordinator ? `${row.coordinator.firstName} ${row.coordinator.lastName}` : 'N/A'}</TableCell>
          );
        },
      });
    }

    const standartProfile = [{
      id: 'first_name',
      width: '20%',
      label: 'First Name',
      render(row: PetParentTableView) {
        return (
          <TableCell sx={{
            maxWidth: 0,
            minWidth: '140px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          >
            {capitalize(row.firstName)}
          </TableCell>
        );
      },
    }, {
      id: 'last_name',
      width: '20%',
      label: 'Last Name',
      render(row: PetParentTableView) {
        return (
          <TableCell sx={{
            maxWidth: 0,
            minWidth: '140px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          >
            {capitalize(row.lastName)}
          </TableCell>
        );
      },
    }, {
      id: 'email',
      width: '30%',
      label: 'Email',
      render(row: PetParentTableView) {
        return (
          <TableCell sx={{
            maxWidth: 0,
            minWidth: '140px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          >
            {row.email.toLowerCase()}
          </TableCell>
        );
      },
    }, {
      id: 'mailchimp_sync_date',
      width: '20%',
      label: 'Date Invited',
      render(row: PetParentTableView) {
        return (
          <TableCell sx={{
            minWidth: '140px',
          }}
          >
            {row.mailchimpSyncDate ? moment(row.mailchimpSyncDate).format('MM/DD/YYYY') : 'N/A'}
          </TableCell>
        );
      },
    }, {
      id: 'animal_count',
      width: '10%',
      label: 'Animals',
      hideOnMobile: true,
      render(row: PetParentTableView) {
        return (
          <TableCell sx={{
            minWidth: '140px',
            display: {
              xs: 'none',
              md: 'table-cell',
            },
          }}
          >
            {row.animalCount}
          </TableCell>
        );
      },
    }];

    return isCoSheltering ? coShelteringProfile : standartProfile;
  }, [isCoSheltering, isCoShelteringCoordinators]);

  useEffect(() => {
    refetch({
      offset: filters.page * filters.limit,
      ...filters,
    });
  }, [filters, refetch]);

  const handleChangeOrder = (_: any, field: string) => {
    const isAsc = filters.orderBy === field && filters.order === 'asc';
    setFilters((prevState) => ({
      ...prevState,
      order: isAsc ? 'desc' : 'asc',
      orderBy: field,
    }));
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setFilters((prevState) => ({
      ...prevState,
      limit: newRowsPerPage,
      page: 0,
    }));
  };

  const handleChangePage = (_: any, page: number) => {
    setFilters((prevState) => ({
      ...prevState,
      page,
    }));
  };

  const coordinatorsSetHandler = (coordinatorsSet: Set<string>) => {
    setFilters((prevState) => ({
      ...prevState,
      coordinators: Array.from(coordinatorsSet),
    }));
    setCoordinatorFilters(coordinatorsSet);
  };

  const searchFieldToggle = (
    <IconButton
      size="small"
      onClick={() => {
        setFilters((prevState) => ({
          ...prevState,
          searchField: prevState.searchField === 'email' ? 'name' : 'email',
        }));
      }}
    >
      <SwapVertOutlined />
    </IconButton>
  );

  const settingsMenu = (
    <IconFilter
      id="search-label"
      labelId="search-label"
      icon={<SwapVertOutlined />}
      disablePortal={false}
    >
      {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        (open, handleClose, handleListKeyDown) => (
          <MenuList
            autoFocusItem={open}
            id="search-composition-menu"
            aria-labelledby="search-composition-button"
            onKeyDown={handleListKeyDown}
          >
            <MenuItem
              onClick={(e) => {
                setFilters((prevState) => ({
                  ...prevState,
                  searchField: 'email',
                }));
                handleClose(e);
              }}
              value="email"
            >
              Search by email
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                setFilters((prevState) => ({
                  ...prevState,
                  searchField: 'name',
                }));
                handleClose(e);
              }}
              value="name"
            >
              Search by name
            </MenuItem>
          </MenuList>
        )
      }
    </IconFilter>
  );

  if (error) throw error;

  return (
    <Box sx={{
      minHeight: '100vh',
    }}
    >
      <Box
        sx={{
          paddingX: {
            xs: 2,
            md: 3,
          },
          paddingY: {
            xs: 1,
            md: 3,
          },
        }}
      >
        <Typography variant="overline">Profiles</Typography>
      </Box>
      <Divider />
      <Box>
        <Grid container>
          <Grid
            item
            xs={0}
            lg={12}
            width="100%"
          >
            <Grid container>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: {
                      xs: 2,
                      md: 3,
                    },
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    gap={2}
                  >
                    <Box sx={{
                        width: {
                          xs: '100%',
                          md: '310px',
                        },
                      }}
                    >
                      <TextField
                        fullWidth
                        margin="normal"
                        name="text-search"
                        type="text"
                        id="text-search"
                        value={searchTextTemp}
                        variant="standard"
                        onChange={(e) => {
                          setSearchTextTemp(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setFilters((prevState) => ({
                              ...prevState,
                              searchText: searchTextTemp,
                            }));
                          }
                        }}
                        label={`Search by ${filters.searchField}`}
                      />
                    </Box>
                    <Box>
                      { awo.preSurrenderDashboard ? settingsMenu : searchFieldToggle }
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: {
                        xs: 'none',
                        md: 'flex',
                      },
                      alignItems: 'center',
                    }}
                    gap={1}
                  >
                    <Box>
                      {isCoShelteringCoordinators && coordinators.length > 0
                        && (
                          <Button
                            startIcon={<FilterAltOutlinedIcon />}
                            variant="outlined"
                            size="small"
                            onClick={() => setCoordinatorFilterDialogOpen(true)}
                          >
                            {`Filters ${coordinatorFilters.size > 0 ? coordinatorFilters.size : ''}`}
                          </Button>
                        )}
                    </Box>
                    {filters.order === 'desc' && filters.orderBy === 'mailchimp_sync_date' ? (null) : (
                      <Box>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setFilters((prevState) => ({
                              ...prevState,
                              order: 'desc',
                              orderBy: 'mailchimp_sync_date',
                            }));
                          }}
                        >
                          Reset Sorting
                        </Button>
                      </Box>
                    )}
                    <Box>
                      <ProfilesDownloadDataChip
                        smsStatus={filters.smsStatus}
                        interests={filters.interests}
                        order={filters.order}
                        orderBy={filters.orderBy}
                        searchText={filters.searchText}
                        searchField={filters.searchField}
                        onlyGroupByPetParent
                      />
                    </Box>
                    <Box>
                      <DateFilter
                        fullWidth
                        selectedDate={filters.daysAgo}
                        onChange={(e: SelectChangeEvent<never>) => {
                          setFilters((prevState) => ({
                            ...prevState,
                            daysAgo: Number(e.target.value),
                          }));
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <ProfilesTable
                  headCells={headCells}
                  page={filters.page}
                  rowsPerPage={filters.limit}
                  rowsPerPageOptions={[25, 50, 100]}
                  order={filters.order}
                  orderBy={filters.orderBy}
                  handleChangeOrder={handleChangeOrder}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  count={data?.petParentCount?.count || 0}
                  loading={loading}
                  petParents={data?.petParents || []}
                  networkStatus={networkStatus}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <CoordinatorFiltersDialog
        coordinators={coordinators}
        open={coordinatorFilterDialogOpen}
        setOpen={setCoordinatorFilterDialogOpen}
        coordinatorFilters={coordinatorFilters}
        setCoordinatorFilters={coordinatorsSetHandler}
      />
    </Box>
  );
}

export default withProfile(ProfilesContainer);
